<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="getMember()"/>
</template>

<script>

  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import moment from "moment";

  export default {
    props: ['billConfig', 'area', 'org'],
    components: {
      jsPDF,
    },
    name: "saveMeterNumber",
    data() {
      return {
        year: '',
        date: ''
      }
    },
    methods: {
      maintainDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `ขอปรับปรุงระบบ`,
          text: 'รายงานจะใช้งานได้หลังวันที่ 1/6/2565',
          acceptText: 'ปิด'
        })
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในใบบันทึกเลขมาตร',
          acceptText: 'ปิด'
        })
      },
      async getMember() {
        this.$vs.loading();
        const bill = this.billConfig.label.split('-');
        this.year = bill[0];
        this.date = this.currentThaiDate();
        const payload = {
          B01: bill[0],
          M02: this.area.value
        };
        await this.$store.dispatch("bookLists/getBookListsForMeterPDF", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const bookLists = res.data;
            this.generatePDF(bookLists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      formatTextDate(text){
        try {
          let dateArr0 = text.split('/');
          let day = dateArr0[0];
          let month = dateArr0[1];
          let year = 0;
          if(dateArr0[2].length === 2){
            year = parseInt(dateArr0[2]) + 2543;
          }else{
            year = parseInt(dateArr0[2]) + 543;
          }
          return day + '/' + month + '/' + year
        }catch (e) {
          return text
        }
      },
      generatePDF(bookLists) {
        const doc = new jsPDF({format: [279, 210], unit:'mm', orientation: 'portrait',});
        const orgData = this.org;
        const area = this.area;
        const billConfig = this.billConfig;
        const year = this.year;
        const date = this.date;
        let title;
        let header;
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'normal');
        doc.setFontSize(14);
        for(let i = 0; i < bookLists.length; i++) {
            doc.autoTable({
                styles: { font: 'THSarabunNew', fontSize: 14, cellPadding: {top: 0.65, bottom: 0.65 } },
                margin: { left: 10, top: 20, right: 10 },
                theme: 'plain',
                columnStyles: {
                    0: {cellWidth: 20},
                    1: {cellWidth: 25},
                    2: {cellWidth: 15},
                    3: {cellWidth: 25},
                    4: {cellWidth: 50},
                    5: {cellWidth: 10},
                    6: {cellWidth: 40},
                },
                body: [
                    [
                        { content: 'ผู้ใช้น้ำ', styles: {fontStyle: 'bold'} },
                        bookLists[i].member_id,
                        { content: bookLists[i].member_name, colSpan: 6 }
                    ],
                    [
                        ' ',
                        { content: bookLists[i].member_address_no + ' ' + bookLists[i].member_soi + ' ' + bookLists[i].member_road + ' ' + bookLists[i].member_address, colSpan: 7 }
                    ],
                    [
                        { content: 'เลขที่มิเตอร์', styles: {fontStyle: 'bold'} },
                        { content: bookLists[i].member_meter_no, colSpan: 2 },
                        { content: 'ชนิดมิเตอร์', styles: {fontStyle: 'bold'} },
                        bookLists[i].member_meter_name,
                        { content: 'พื้นที่', styles: {fontStyle: 'bold'} },
                        area.label
                    ]
                ],
                didDrawPage: function (data) {
                    // Header
                    doc.setFontSize(18);
                    doc.text(orgData.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
                    doc.text(orgData.Org03, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
                    doc.setFontSize(16);
                },
            });
            let array = [];
            for(let j = 0; j < bookLists[i].book_lists.length; j++){
              const billArr = bookLists[i].book_lists[j];
              const B17 = this.formatTextDate(billArr.B17)
              const B19 = this.formatTextDate(billArr.B19)
              array.push([j + 1, billArr.CB03, billArr.B01, billArr.CB04, B17, billArr.B18, B19, billArr.B20, billArr.B21])
            }
            doc.autoTable({
                styles: { font: 'THSarabunNew', fontSize: 14 },
                margin: { left: 10, top: 20, right: 10 },
                theme: 'plain',
                head: [['ลำดับ', 'ปีงบ', 'รหัสรอบ', 'ชื่อรอบ/ประจำเดือน', 'จดครั้งก่อน', 'เลขจดก่อน', 'จดครั้งหลัง', 'เลขจดหลัง', 'หน่วยที่ใช้']],
                body: array
            });
        }
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        let nameDate = moment().format('DD-MM-YYYY');
        let date0 = nameDate.split('-');
        let nameYear = parseInt(date0[2]) + 543;
        let formDate = date0[0] + '-' + date0[1] + '-' + nameYear;
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1 ; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save(`ใบบันทึกเลขมาตร` + formDate + '.pdf');
      }
    },
    async created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>

<style scoped>
</style>
